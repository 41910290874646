<template>
    <div>
        <div class="top">
            <div class="logo">
                <el-image :src="require('@/assets/logo.png')"></el-image>
            </div>
        </div>
        <div class="banner">
            <div class="hidden-xs-only nav">
                <el-image :src="require('@/assets/survey/banner.png')"></el-image>
            </div>
            <div class="hidden-sm-and-up">
                <el-image :src="require('@/assets/survey/ibanner.png')"></el-image>
            </div>
        </div>
        <div class="appmain">
            <div class="zunjing">
                <div class="hidden-xs-only nav">
                    <el-image style="width:100%" :src="require('@/assets/survey/zunjing.png')"></el-image>
                </div>
                <div class="hidden-sm-and-up">
                    <el-image style="width:100%" :src="require('@/assets/survey/izunjing.png')"></el-image>
                </div>
            </div>
            <div class="content">
                <el-form ref="form" :rules="rules" :model="form" :label-position="labelPosition" label-width="290px">
                    <el-form-item label="公司名称" prop="companyName">
                        <el-input :disabled="disabled" v-model="form.companyName"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" prop="name">
                        <el-input :disabled="disabled" v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="职位" prop="position">
                        <el-input :disabled="disabled" v-model="form.position"></el-input>
                    </el-form-item>
                    <el-form-item label="电话" prop="phone">
                        <el-input :disabled="disabled" v-model="form.phone"></el-input>
                    </el-form-item>
                    <el-form-item label="贵公司主要使用的产品型号" prop="productType">
                        <el-input :disabled="disabled" v-model="form.productType"></el-input>
                    </el-form-item>
                    <el-form-item label="产品性能" prop="performance">
                        <el-radio-group :disabled="disabled" v-model="form.performance">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="外观质量" prop="appearance">
                        <el-radio-group :disabled="disabled" v-model="form.appearance">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="包装质量" prop="packing">
                        <el-radio-group :disabled="disabled" v-model="form.packing">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="产品性价比" prop="quality">
                        <el-radio-group :disabled="disabled" v-model="form.quality">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="咨询、指导和培训服务" prop="service">
                        <el-radio-group :disabled="disabled" v-model="form.service">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="交货及时性" prop="delivery">
                        <el-radio-group :disabled="disabled" v-model="form.delivery">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="售后服务水平" prop="aftermarket">
                        <el-radio-group :disabled="disabled" v-model="form.aftermarket">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="服务态度" prop="attitude">
                        <el-radio-group :disabled="disabled" v-model="form.attitude">
                            <el-radio label="非常满意"></el-radio>
                            <el-radio label="满意"></el-radio>
                            <el-radio label="可以接受"></el-radio>
                            <el-radio label="不满意"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="总体满意度" prop="satisfaction">
                        <el-rate
                            style="margin-top: 10px;"
                            v-model="form.satisfaction"
                            :texts="['极差', '失望', '一般', '满意', '非常满意']"
                            show-text>
                        </el-rate>
                    </el-form-item>
                    <el-form-item label="您对我们现有产品有哪些意见和建议" prop="proposal">
                        <el-input :disabled="disabled" :autosize="{ minRows: 3, maxRows: 6}" type="textarea" v-model="form.proposal"></el-input>
                    </el-form-item>
                    <el-form-item label="您期望众智科技未来能提供哪些产品和支持" prop="expect">
                        <el-input :disabled="disabled" :autosize="{ minRows: 3, maxRows: 6}" type="textarea" v-model="form.expect"></el-input>
                    </el-form-item>
                    <el-form-item label="贵公司现状" prop="state">
                        <el-input :disabled="disabled" :autosize="{ minRows: 3, maxRows: 6}" type="textarea" v-model="form.state"></el-input>
                    </el-form-item>
                    <el-form-item label="贵公司未来发展方向" prop="direction">
                        <el-input :disabled="disabled" :autosize="{ minRows: 3, maxRows: 6}" type="textarea" v-model="form.direction"></el-input>
                    </el-form-item>
                    <div class="jingxuan">
                        获奖客户被选出后会由销售经理进行联系邮寄礼品！感谢您的配合，众智科技祝您工作顺利，万事大吉！
                    </div>
                    <div v-if="!disabled" @click="submit" style="width: 296px;height: 48px;background: #0052D9;color: #fff;text-align: center;line-height: 48px;margin: 0 auto;margin-top: 20px;margin-bottom: 20px;cursor: pointer;">
                        提交
                    </div>
                </el-form>
            </div>
        </div>
    </div>
</template>
<script>
import { surveyAdd } from "@/api/api"; 
export default {
    data(){
        return{
            form:{},
            labelPosition:'right',
            rules:{
                companyName: [
                    { required: true, message: '请填写公司名称', trigger: 'change' }
                ],
                name: [
                    { required: true, message: '请填写姓名', trigger: 'change' }
                ],
                position: [
                    { required: true, message: '请填写职位', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '请填写电话', trigger: 'change' }
                ],
                productType: [
                    { required: true, message: '请填写贵公司主要使用的产品型号', trigger: 'change' }
                ],
                performance: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                appearance: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                packing: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                quality: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                service: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                delivery: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                aftermarket: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                attitude: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                satisfaction: [
                    { required: true, message: '请选择', trigger: 'change' }
                ],
                proposal: [
                    { required: true, message: '请填写您对我们现有产品有哪些意见和建议', trigger: 'change' }
                ],
                expect: [
                    { required: true, message: '请填写您的期望', trigger: 'change' }
                ],
                state: [
                    { required: true, message: '请填写贵公司现状', trigger: 'change' }
                ],
                direction: [
                    { required: true, message: '请填写贵公司未来发展方向', trigger: 'change' }
                ],
            },
            disabled:false
        }
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    created(){
        if (this.isMobile()) {
            this.labelPosition = 'top'
        }else{
            this.labelPosition = 'right'
        }
    },
    methods:{
        isMobile() {
            let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
            return flag;
        },
        submit(){
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if(this.form.satisfaction == 0){
                        this.$message.error('请选择总体满意度')
                        return
                    }
                    if(this.form.satisfaction == 1){
                        this.form.satisfaction = '极差'
                    }else if(this.form.satisfaction == 2){
                        this.form.satisfaction = '失望'
                    }else if(this.form.satisfaction == 3){
                        this.form.satisfaction = '一般'
                    }else if(this.form.satisfaction == 4){
                        this.form.satisfaction = '满意'
                    }else if(this.form.satisfaction == 5){
                        this.form.satisfaction = '非常满意'
                    }
                    surveyAdd(this.form).then((res)=>{
                        this.$message.success('反馈成功')
                        this.disabled = true
                    })
                }
            });
        }
    }
}
</script>
<style lang="scss" scoped>
    .top{
        width: 80%;
        height: 60px;
        margin: 0 auto;
    }
    .logo{
        padding-top: 10px;
    }
    .appmain{
        width:80%;
    }
    .content{
        width: 80%;
        margin: 0 auto;
    }
    .jingxuan{
        margin-left: 280px;
    }
    // 手机端样式
    @media screen and (max-width: 480px) {
        .logo{
            width: 150px;
            margin: 0 auto;
        }
        .appmain{
            width: 100%;
            .content{
                width: 90%;
                .jingxuan{
                    margin-left: 0px;
                }
            }
        }
    }
</style>